import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, slug, tags, imageUrl, diskripsi }) => {
  return (
    <Helmet>
      <html lang="id" />
      <title>{title}</title>
      <meta name="description" content={diskripsi} />
      <meta name="image" content={`https://hajebo.com${imageUrl}`} />
      <link rel="canonical" href={`https://hajebo.com${slug}`} />
      <meta name="keyword" content={tags} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={`https://hajebo.com${slug}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={diskripsi} />
      <meta property="og:image" content={`https://hajebo.com${imageUrl}`} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@hajebo_" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={diskripsi} />
      <meta name="twitter:image" content={`https://hajebo.com${imageUrl}`} />
    </Helmet>
  )
}

export default Seo

import React from "react"
import { Link } from "gatsby"
import Breadcrumbs from "../schema/breadcrumb2"
const Bread = ({ nama2 }) => {
  return (
    <ol
      aria-label="breadcrumbs"
      className="flex flex-row text-xs mt-8 lg:my-16 md:mx-20 lg:mx-56 mb-10 mx-2 flex-wrap"
    >
      <li className="border-2 md:text-lg lg:text-xl lg:rounded-lg lg:px-3 lg:py-1 text-white font-medium border-green-700 bg-green-500 rounded px-1 mx-1">
        <Link to="/">Home</Link>
      </li>
      <span className="md:text-lg lg:text-xl">&gt;</span>
      <li className="border-2 md:text-lg lg:text-xl lg:rounded-lg lg:px-3 lg:py-1 text-gray-700 font-medium border-green-700 bg-green-500 rounded px-1 mx-1">
        {nama2}
      </li>
      <Breadcrumbs name2={nama2} />
    </ol>
  )
}

export default Bread

import { BreadcrumbList } from "schema-dts"
import React from "react"
import { JsonLd } from "react-schemaorg"

export function Bread({ name2 }) {
  return (
    <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        name: name2,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://hajebo.com/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: name2,
          },
        ],
      }}
    />
  )
}

export default Bread

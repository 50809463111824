import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seoPage"
import Bread from "../components/breadPage"

const Privasi = () => {
  return (
    <main>
      <Seo
        title="Kebijakan privasi hajebo"
        diskripsi="Kebijakan privasi yang mengatur privasi penguna setia hajebo blog. Pembahasan mengenai privasi dan data apa yang kami simpan"
        slug="/kebijakan-privasi/"
        tags="bebijakan privasi"
        imageUrl="https://hajebo.com/logo.png"
      />
      <Bread nama2="kebijakan privasi" />
      <div className="mx-4 md:mx-10 lg:mx-20">
        <h1 className="text-center m-8 md:m-10 text-3xl font-extrabold">
          Kebijakan Privasi
        </h1>
        <div className="text-lg">
          <p>
            <Link className="font-bold" to="/">
              Hajebo.com
            </Link>{" "}
            sangat menghargai privasi dari para pengunjungnya. Oleh karena itu,
            berikut kami akan menjabarkan berbagai macam data pribadi dari para
            pengunjung yang dicatat dan dikumpulkan oleh kami serta bagaimana
            kami menggunakan data tersebut.
          </p>
          <h2 className="text-xl my-4 font-semibold">Log file</h2>
          <p>
            Sama halnya seperti website lain, heyapakabar.com juga menggunakan
            log file. Berbagai informasi yang terdapat pada file tersebut di
            antaranya adalah alamat internet protocol (IP), jenis browser,
            Internet Service Provider (ISP), tanggal dan waktu, halaman yang
            dikunjungi, dan jumlah klik yang dilakukan. Semua data tersebut
            digunakan untuk menganalisis tren, perilaku pengunjung, serta
            informasi demografik. Berbagai data tersebut tidak kami gunakan
            untuk melacak identitas pribadi dari para pengunjung.
          </p>
          <h2 className="text-xl my-4 font-semibold">Cookies</h2>
          <p>
            Hajebo.com menggunakan cookie untuk mengumpulkan informasi mengenai
            aktivitas yang dilakukan oleh pengunjung. Kami juga bekerjasama
            dengan Google Adsense dan mitra lainnya untuk menayangkan iklan yang
            mengambil informasi dari cookie tersebut. Jika Anda ingin
            menonaktifkan cookie tersebut, silahkan nonaktifkan secara langsung
            melalui browser yang Anda gunakan.
          </p>
          <p className="my-4">
            Apabila Anda membutuhkan informasi lainnya atau memiliki pertanyaan
            mengenai dokumen kebijakan ini, silahkan hubungi kami melalui email
            ke alamat{" "}
            <a className="font-bold" href="mailto:hajebo.com@gmail.com">
              hajebo.com@gmail.com
            </a>{" "}
          </p>
        </div>
      </div>
    </main>
  )
}

export default Privasi
